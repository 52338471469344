import * as React from 'react';
import { useRouter } from 'next/router';
import { StateManager } from '@/features/@components/general';
import { ListEvents } from '@/features/ListEvents';
const { useEffect } = React;
const version = require('../../../../package.json').version;
export default function ListEventsView(props) {
  const router = useRouter();
  const { appId, showCart, setShowCart } = props;
  const isLoadingRouter = !router.isReady;

  useEffect(() => {
    console.log(`BuildNumber:${version}`);
  }, []);

  return (
    <StateManager loading={ isLoadingRouter}>
      <ListEvents
        showCart={showCart}
        setShowCart={setShowCart}
        slugName={router?.query?.appId}
        appId={appId}
        setNavTitle={props.setNavTitle}
        app={props.app}
      />
    </StateManager>
  );
}

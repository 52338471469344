
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[appId]/events",
      function () {
        return require("private-next-pages/[appId]/events/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[appId]/events"])
      });
    }
  
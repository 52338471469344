import * as React from 'react';
import { useIsMobile } from '@/utils/useIsMobile';
import { useRequest } from '@/api';
import { CartContext } from '@/context/Cart';
import dynamic from 'next/dynamic';
import { Loading } from '../@components/general';
import { useTranslateKey } from '@/utils/translateKey';
import { translateEntities, translateEntity } from '@/utils/translateEntity';

const EventsMobile = dynamic(() => import('./mobile/EventsMobile'), {
  loading: () => <Loading />
});
const EventsWeb = dynamic(() => import('./web/EventsWeb'), {
  loading: () => <Loading />
});

const QUERY_EVENTS = require('./queries/QUERY_EVENTS.gql');

const { useContext, useState } = React;

export function ListEvents({
  appId,
  showCart,
  setShowCart,
  setNavTitle,
  slugName,
  app
}) {
  const translateKeyAllEvents = useTranslateKey('AllEvents');
  const [categoryFilter, setCategoryFilter] = useState({
    name: translateKeyAllEvents,
    id: 'All Events'
  });
  const isMobile = useIsMobile();
  const cartContext = useContext(CartContext);
  const cart = cartContext?.cart;

  const { data, loading } = useRequest(QUERY_EVENTS, {
    appId
  });

  const locale = navigator.language;
  const eventsList = translateEntities(
    data?.getApp?.listAppEvents?.items,
    locale
  );
  const categoriesData = translateEntities(
    data?.getApp?.listAppCategories?.items,
    locale
  );
  const timezoneName = data?.getApp?.settings?.timezoneName;

  const categories = [
    {
      name: translateKeyAllEvents,
      id: 'All Events'
    },
    ...(categoriesData
      ?.filter((category) => category?.listCategoryEvents?.items?.length > 0)
      ?.sort((a, b) => a.sortNumber - b.sortNumber) ?? [])
  ];

  const sponsors = data?.getApp?.sponsors
  const eventSponsor = translateEntity(sponsors, locale)?.eventSponsor

  return (
    <>
      {isMobile && !loading && (
        <EventsMobile
          appId={appId}
          slugName={slugName}
          eventsList={eventsList}
          categories={categories}
          timezoneName={timezoneName}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          showCart={showCart}
          setShowCart={setShowCart}
          setNavTitle={setNavTitle}
          cart={cart}
          eventSponsor={eventSponsor}
          app={app}
        />
      )}
      {!isMobile && !loading && (
        <EventsWeb
          appId={appId}
          slugName={slugName}
          showCart={showCart}
          setShowCart={setShowCart}
          eventsList={eventsList}
          timezoneName={timezoneName}
          categories={categories}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          cart={cart}
          eventSponsor={eventSponsor}
          app={app}
        />
      )}
    </>
  );
}
